.btn-Home {
  border-radius: 100px !important;
}
.footer-title {
  margin-bottom: 30px;
  color: #ffffff;
  text-transform: capitalize;
}
.footer-area .footer-top {
  padding: 70px 0;
}
@media (max-width: 767px) {
  .footer-area .footer-top {
    padding: 50px 0;
  }
}

.copyright-area a {
  color: rgba(255, 255, 255, 0.8);
}
.copyright-area a:hover {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.footer-items ul {
  padding-left: 0;
}
.footer-items ul li {
  margin: 0.8rem 0;
  list-style: none;
}

.footer-items ul li a {
  text-decoration: none;
  color: white;
}
.footer-area .footer-items .subscribe-form {
  position: relative;
}
.footer-area .footer-items .navbar-brand {
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 0;
  color: white;
}

.footer-area .footer-items .navbar-brand img {
  cursor: pointer;
}

.footer-area .footer-items .subscribe-form .form-control {
  height: 50px;
  border: none;
  outline: none;
  padding-left: 20px;
  border-radius: 6px;
  background-color: #191919;
  color: white;
}
.footer-area .footer-items .subscribe-form .form-control:focus {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer-area .footer-items .subscribe-form input {
  font-size: 14px;
  text-indent: 3%;
}

@media (max-width: 575px) {
  .footer-area .footer-items .subscribe-form input {
    text-indent: 0;
  }
}

.res-margin {
  margin-bottom: 30px;
}

.social-icons svg {
  display: block;
  height: 100%;
  line-height: 45px;
  margin: 0 auto;
}

.social-icons > a {
  margin: 5px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background: #222;
  color: white !important;
  border-radius: 3px;
}
.social-icons > a:hover {
  text-decoration: none;
}
.footer-area .copyright-area {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-area .copyright-area a {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area .footer-items .subscribe-form button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 16px 25px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.clip-text,
.text-effect,
.intro span,
.hero-section span,
.share-list .nav-link i {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.author-area .item-form {
  padding: 50px 35px;
}
.btn-Main {
  background-image: linear-gradient(150deg, #57048a 0%, #4528dc 78%);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.btn-Main:focus {
  background-image: linear-gradient(150deg, #57048a 0%, #4528dc 78%);
  outline: none;
}
.iconMain {
  font-size: 50px;
  background-image: linear-gradient(150deg, #57048a 0%, #4528dc 78%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.swiper {
  width: 100%;
  height: 300px;
}
.mainCard {
  background-color: #111111 !important;
}
.cardPadding {
  padding: 20px;
}
@media only screen and (max-width: 500px) {
  .cardPadding {
    padding: 20px 10px !important;
  }
}
@media only screen and (max-width: 600px) {
  .nav-item {
    margin: 15px 0px !important;
  }
}
.inputField {
  background-color: black !important;
}
.author {
  display: flex !important;
  justify-content: center !important;
  margin-top: -40px;
  z-index: 100;
}
.author-thumb {
  border: 3px solid rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(9, 8, 13, 0.15);
}
.btn-smaller {
  font-size: 0.7rem !important;
  padding: 7px 14px !important;
}
.card-body .input-group .form-control {
  border-radius: 100px;
  background-color: transparent;
  border: 2px solid;
  height: 40px;
  padding: 1.5rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.card .card-body .input-group .input-group-append {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 100px;
  overflow: hidden;
}

.card .card-body .input-group .input-group-append button {
  margin-right: 5px;
  padding: 12px 16px;
  background-color: transparent;
  color: var(--white-color);
  border: 0;
  outline: none;
}
.navBtn {
  width: 170px;
}
.card .card-body hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px dashed rgba(255, 255, 255, 0.15);
}
.custom-file-label::after {
  background-color: transparent !important;
  content: "Upload" !important;
}
.card {
  padding: 0px 2px 10px 2px;
  margin-bottom: 30px;
  border: none;
  border-radius: 8px;
  background-color: #111111 !important;
  -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
}
.cardAll {
  height: auto;
  padding: 20px 0px 20px 0px;
}
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 35px 0 0;
}
.image-over {
  width: 100%;
  height: auto;
}
.firstSection {
  padding-top: 100px !important;
  padding-bottom: 150px !important;
}
.inputColor {
  background-color: #111111 !important;
}
.single-wallet {
  padding: 30px 20px;
  height: 250px;
}
.single-tab-list {
  margin: 20px 0px 20px 0px;
}
.JumbMain {
  background-color: black !important;
  min-height: 100vh;
}
.placeBtn {
  width: 100%;
}
